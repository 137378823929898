import { mobileApplicationRoutePath } from '@bcf-v2/routes-landings/common/app/paths/mobile-application-route-path';
import { aboutUsRoutePath } from '@bcf-v2/routes-statics/common/app/paths/about-us-route-path';
import { privacyPolicyRoutePath } from '@bcf-v2/routes-statics/common/app/paths/privacy-policy-route-path';
import { promotionsRoutePath } from '@bcf-v2/routes-statics/common/app/paths/promotions-route-path';
import { responsibleGamingRoutePath } from '@bcf-v2/routes-statics/common/app/paths/responsible-gaming-route-path';
import { sitemapRoutePath } from '@bcf-v2/routes-statics/common/app/paths/sitemap-route-path';
import { supportRoutePath } from '@bcf-v2/routes-statics/common/app/paths/support-route-path';
import { termsRoutePath } from '@bcf-v2/routes-statics/common/app/paths/terms-route-path';
import { vipClubRoutePath } from '@bcf-v2/routes-statics/common/app/paths/vip-club-route-path';
import { cashoutRoutePath } from '@bcf-v2/routes-statics/sportsbook/app/paths/cashout-route-path';
import { liveCasinoRoutePath } from '@bcf-v2/routes/casino/app/paths/live-casino-route-path';
import { slotsRoutePath } from '@bcf-v2/routes/casino/app/paths/slots-route-path';
import { tableGamesRoutePath } from '@bcf-v2/routes/casino/app/paths/table-games-route-path';
import { tournamentsRoutePath } from '@bcf-v2/routes/casino/app/paths/tournaments-route-path';
import { homeRouteI18n } from '@bcf-v2/routes/common/app/i18n/home-route-i18n';
import { FooterLink } from '../types';

export const footerLinksFn = (): FooterLink[][] => [
  [
    {
      url: aboutUsRoutePath(),
      title: $localize`About us`
    },
    {
      url: 'https://lvbetpartners.com/',
      title: $localize`Affiliates`
    },
    {
      url: 'https://lvbet.com/casino-blog/',
      title: $localize`Casino Blog`
    }
  ],
  [
    {
      url: homeRouteI18n(),
      title: $localize`Casino`
    },
    {
      url: 'https://lvbet.com/sports/',
      title: $localize`Sports`
    },
    {
      url: liveCasinoRoutePath(),
      title: $localize`Live Casino`
    },
    {
      url: slotsRoutePath(),
      title: $localize`Slots`
    },
    {
      url: tableGamesRoutePath(),
      title: $localize`Table Games`
    },
    {
      url: promotionsRoutePath(),
      title: $localize`Bonuses`
    }
  ],
  [
    {
      url: tournamentsRoutePath(),
      title: $localize`Tournaments`
    },
    {
      url: mobileApplicationRoutePath(),
      title: $localize`Mobile application`
    },
    {
      url: cashoutRoutePath(),
      title: $localize`Cashout`
    },
    {
      url: vipClubRoutePath(),
      title: $localize`Vip Club`
    }
  ],
  [
    {
      url: supportRoutePath(),
      title: $localize`Support`
    },
    {
      url: termsRoutePath(),
      title: $localize`Terms and conditions`
    },
    {
      url: responsibleGamingRoutePath(),
      title: $localize`Responsible gaming`
    },
    {
      url: privacyPolicyRoutePath(),
      title: $localize`Privacy Policy`
    },
    {
      url: sitemapRoutePath(),
      title: $localize`Sitemap`
    }
  ]
];

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CmsApiConfig } from '@bcf-logic-api-access/misc/cms-api-config';
import { CmsCasinoGameConfig } from '@bcf-logic-shared/models/cms-casino-game-config';
import { DeviceTypeInfo } from '@bcf-shared-settings/settings/device-type-info';
import { ApiCountryIpSyncedLegacy } from '@bcf-v2-api-access/services/api-country-ip.synced-legacy';
import { Observable, catchError, map, shareReplay, switchMap, throwError } from 'rxjs';

type RequestByCountry<T> = (countryIp: string) => Observable<T>;

@Injectable({
  providedIn: 'root'
})
export class CmsApiCountrySwitchable {
  private _getUserDataPackageShareRef$!: Observable<Record<string, any>>;
  private _getCasinoGamesConfigsShareRef$!: Observable<CmsCasinoGameConfig[]>;

  constructor(
    private _http: HttpClient,
    private _apiCountryIp: ApiCountryIpSyncedLegacy,
    private _deviceTypeInfo: DeviceTypeInfo,
    private _cmsApiConfig: CmsApiConfig
  ) {}

  public getUserDataPackage(): Observable<Record<string, any>> {
    const getRequest: RequestByCountry<Record<string, any>> = (countryIp: string) =>
      this._http
        .get(`${this._cmsApiConfig.jsonUrl()}data-user.json${this._cmsApiConfig.insertSlashOrNot}?country=${countryIp}`)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            console.warn('FAILED LOAD OF data-user.json');
            return throwError(err);
          })
        );

    return (this._getUserDataPackageShareRef$ ??= this._apiCountryIp.countryIp$.pipe(
      switchMap((countryIp: string) => getRequest(countryIp)),
      shareReplay(1)
    ));
  }

  public getCasinoGamesConfigs(): Observable<CmsCasinoGameConfig[]> {
    const getRequest: RequestByCountry<CmsCasinoGameConfig[]> = (countryIp: string) =>
      this._http
        .get<
          Record<string, any>[]
        >(`${this._cmsApiConfig.jsonUrl()}games-list.json?country=${countryIp}&device=${this._deviceTypeInfo.typeMappedToCms}`)
        .pipe(
          map(CmsCasinoGameConfig.fromJson),
          catchError((err: HttpErrorResponse) => {
            console.warn('FAILED LOAD OF data-user.json');
            return throwError(err);
          })
        );

    return (this._getCasinoGamesConfigsShareRef$ ??= this._apiCountryIp.countryIp$.pipe(
      switchMap((userCountry: string) => getRequest(userCountry)),
      shareReplay(1)
    ));
  }
}

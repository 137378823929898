import { Injectable } from '@angular/core';
import { WorkerSynchronizer, WorkerSynchronizerSpawner } from '@bcf-v2-platforms/platform-worker/worker-synchronizer';
import { transferRxNext } from '@bcf-vanilla-ts-v1-shared/misc/rx-helpers/transfer-rx-next';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/** @deprecated should be removed after switch all code to vanilla-ts */
export class ApiCountryIpSyncedLegacy {
  private _countryIpChannel: WorkerSynchronizer<string> =
    this._workerSynchronizerSpawner.spawn('_workerApiCountryIpSynced');

  public countryIp$: ReplaySubject<string> = new ReplaySubject<string>(1);

  constructor(private _workerSynchronizerSpawner: WorkerSynchronizerSpawner) {
    this._init();
  }

  private _init(): void {
    this._countryIpChannel.message$.subscribe(transferRxNext(this.countryIp$));
  }
}
